const permissions = (
  state = {
    isAdmin: false,
    isSuperAdmin: false,
    isOrgAdmin: false,
    setIsDataSteward: false,
    permissions: {
      accessTocViewer: false,
      accessLegacyTocViewer: false,
      accessRmViewer: false,
      accessFileSub: false,
      accessManageAcrs: false,
      accessLegacyManageAcrs: false,
      accessEcms: false,
      accessRmDashboard: false,
      accessConform: false,
      accessFallout: false,
      accessSolutionsCenter: false,
      accessDiretto: false,
      accessHomePs: false,
      accessOperations: false,
      accessSdohReporting: false,
    },
    orgName: ''
  },
  action
) => {
  switch (action.type) {
    case 'SET_IS_ADMIN':
      return {
        ...state,
        isAdmin: action.payload,
      };
    case 'SET_IS_SUPER_ADMIN':
      return {
        ...state,
        isSuperAdmin: action.payload,
      };
    case 'SET_IS_ORG_ADMIN':
      return {
        ...state,
        isOrgAdmin: action.payload,
      };
    case 'SET_IS_DATA_STEWARD':
      return {
        ...state,
        isDataSteward: action.payload,
      };
    case 'SET_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload,
      };
    case 'SET_ORG_NAME':
      return {
        ...state,
        orgName: action.payload,
      };
    default:
      return state;
  }
};

export default permissions;

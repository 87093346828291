import React, { useContext, useEffect, useState } from 'react';

import { Button, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Brand from '../Brand/Brand';

import { AuthContext } from '../../context/AuthContext';

import './TopNav.css';

const TopNav = () => {
  const template = process.env.REACT_APP_TEMPLATE;

  const { user, extUser, handleSignout } = useContext(AuthContext);
  const [openAccountDropdown, setOpenAccountDropdown] = useState(false);
  const [
    openCareCoordinationDropdown,
    setOpenCareCoordinationDropdown,
  ] = useState(false);
  const [openAdministrativeDropdown, setOpenAdministrativeDropdown] = useState(
    false
  );
  const [navBarHeader, setNavBarHeader] = useState("");

  // const accessToken = useSelector((state) => state.tokens.accessToken);
  // const idToken = useSelector((state) => state.tokens.idToken);
  // const refreshToken = useSelector((state) => state.tokens.refreshToken);

  const permissions = useSelector((state) => state.permissions);

  const { isSuperAdmin } = permissions;

  const {
    accessTocViewer,
    accessLegacyTocViewer,
    accessRmViewer,
    accessManageAcrs,
    accessLegacyManageAcrs,
    accessFileSub,
    accessEcms,
    accessRmDash,
    accessSolutionsCenter,
    accessIqb,
    accessHd,
    accessConform,
    accessDiretto,
    accessOperations,
    accessSdohReporting
  } = permissions.permissions;

  const showCareCoordination = () => {
    if (
      accessTocViewer ||
      accessFileSub ||
      accessManageAcrs ||
      accessSolutionsCenter ||
      accessLegacyTocViewer ||
      accessLegacyManageAcrs
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showAdministrative = () => {
    if (
      accessRmViewer ||
      accessRmDash ||
      accessHd ||
      accessIqb ||
      accessConform ||
      accessSdohReporting
    ) {
      return true;
    } else {
      return false;
    }
  };

  //allows the header text to be set to a custom name based on the template env variable
  useEffect(() => {
    if (template === 'gahin') {
      setNavBarHeader("GeorgiaConnX")
    }
    else if (template === 'njhin') {
      setNavBarHeader("NJHIN");
    }
    else {
      setNavBarHeader("MIGateway");
    }
  }, [template])

  return (
    <div>
      <Navbar
        bg="custom"
        expand="lg"
        variant="dark"
        className="migateway-navbar"
      >
        <Navbar.Brand>
          <NavLink to="/">
            <Brand title={navBarHeader} />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {user || extUser ? (
            <>
              <Container>
                <Nav className="mr-auto">
                  <NavLink exact to="/" className="nav-link nav-link-margin">
                    Home
                  </NavLink>
                  {showCareCoordination() && (
                    <NavDropdown
                      title="Care Coordination"
                      id="care-coordination-nav-dropdown"
                      className="nav-link-margin"
                      onMouseEnter={() => setOpenCareCoordinationDropdown(true)}
                      onMouseLeave={() =>
                        setOpenCareCoordinationDropdown(false)
                      }
                      show={openCareCoordinationDropdown}
                    >
                      {accessTocViewer && (
                        <NavLink exact to="/tocviewer" className="nav-link">
                          TOC Viewer
                        </NavLink>
                      )}
                      {accessLegacyTocViewer && (
                        <NavLink exact to="/legacytocviewer" className="nav-link">
                          TOC_Viewer
                        </NavLink>
                      )}
                      {accessFileSub && (
                        <NavLink
                          exact
                          to="/filesubmission"
                          className="nav-link"
                        >
                          File Submission
                        </NavLink>
                      )}
                      {accessManageAcrs && (
                        <NavLink exact to="/manageacrs" className="nav-link">
                          Manage ACRS
                        </NavLink>
                      )}
                      {accessLegacyManageAcrs && (
                        <NavLink exact to="/legacymanageacrs" className="nav-link">
                          Manage_ACRS
                        </NavLink>
                      )}
                      {accessSolutionsCenter && (
                        <NavLink exact to="/inbox" className="nav-link">
                          Inbox
                        </NavLink>
                      )}
                    </NavDropdown>
                  )}
                  {accessDiretto && (
                    <NavLink
                      exact
                      to="/direttoinbox"
                      className="nav-link nav-link-margin"
                    >
                      Diretto Inbox
                    </NavLink>
                  )}
                  {showAdministrative() && (
                    <NavDropdown
                      title="Administrative"
                      id="administrative-nav-dropdown"
                      className="nav-link-margin"
                      onMouseEnter={() => setOpenAdministrativeDropdown(true)}
                      onMouseLeave={() => setOpenAdministrativeDropdown(false)}
                      show={openAdministrativeDropdown}
                    >
                      {accessRmViewer && (
                        <NavLink
                          exact
                          to="/readmissionviewer"
                          className="nav-link"
                        >
                          Readmission Viewer
                        </NavLink>
                      )}
                      {accessRmDash && (
                        <NavLink
                          exact
                          to="/readmissiondashboard"
                          className="nav-link"
                        >
                          Readmission Dashboard
                        </NavLink>
                      )}
                      {accessConform && (
                        <NavLink exact to="/conformance" className="nav-link">
                          Conformance Reporting
                        </NavLink>
                      )}
                      {accessSdohReporting && (
                        <NavLink exact to="/sdohreporting" className="nav-link">
                          SDOH Reporting
                        </NavLink>
                      )}
                      {accessHd && (
                        <NavLink
                          exact
                          to="/healthdirectory"
                          className="nav-link"
                        >
                          Health Directory
                        </NavLink>
                      )}
                      {accessIqb && (
                        <NavLink exact to="/iqb" className="nav-link">
                          Intelligent Query Broker
                        </NavLink>
                      )}
                    </NavDropdown>
                  )}
                  {accessEcms && (
                    <NavLink exact to="/ecms" className="nav-link">
                      eCMS
                    </NavLink>
                  )}
                  <NavLink exact to="/support" className="nav-link">
                    Support
                  </NavLink>
                </Nav>
                {user && (
                  <>
                    <div className="mr-sm-2">
                      <span className="float-left nav-link account">
                        <i className="material-icons">account_circle</i>{' '}
                      </span>

                      <NavDropdown
                        title={user.attributes.given_name.replace(/['"]+/g, '')}
                        id="user-nav-dropdown"
                        onMouseEnter={() => setOpenAccountDropdown(true)}
                        onMouseLeave={() => setOpenAccountDropdown(false)}
                        show={openAccountDropdown}
                      >
                        <NavLink exact to="/settings" className="nav-link">
                          Settings
                        </NavLink>
                        {isSuperAdmin && (
                          <>
                            <NavLink exact to="/admin" className="nav-link">
                              Admin
                            </NavLink>
                            <NavLink
                              exact
                              to="/admin/organizationadmin"
                              className="nav-link"
                            >
                              Organization Management
                            </NavLink>
                            <NavLink
                              exact
                              to="/admin/oidmapperadmin"
                              className="nav-link"
                            >
                              OID Mapping Management
                            </NavLink>
                          </>
                        )}
                        {accessOperations && (
                          <NavLink
                            exact
                            to="/operationsadmin"
                            className="nav-link"
                          >
                            Operations
                          </NavLink>
                        )}
                        <NavDropdown.Divider />
                        <Button
                          className="nav-link"
                          onClick={handleSignout}
                          variant="link"
                        >
                          Logout
                        </Button>
                      </NavDropdown>
                    </div>
                  </>
                )}
              </Container>
            </>
          ) : null}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default TopNav;
